var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"preview-layout"},[_c('div',{staticClass:"preview-image d-flex justify-content-between"},[(_vm.propsData[0])?_c('div',{staticClass:"preview__img",class:{
          'preview-image--one': _vm.propsData.length == 1,
          'preview-image--two': _vm.propsData.length >= 2
        },style:([
          _vm.propsData[0].type === 'video'
            ? ''
            : { backgroundImage: 'url(' + _vm.propsData[0].url + ')' }
        ]),on:{"click":function($event){return _vm.showImage(0)}}},[(_vm.propsData[0].type === 'video')?_c('video',{staticClass:"video-layout",attrs:{"width":"100%","height":"100%","controlslist":"nodownload","preload":"metadata","type":"video/*","controls":"","autoplay":"","playsinline":"","muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.propsData[0].url,"type":"video/mp4"}}),_c('source',{attrs:{"src":_vm.propsData[0].url,"type":"video/mov"}})]):_vm._e()]):_vm._e(),(_vm.propsData.length > 1)?_c('div',{staticClass:"d-flex flex-column justify-content-between",class:{
          'preview-image--two': _vm.propsData.length >= 2
        }},_vm._l((_vm.getData()),function(image,index){return _c('div',{key:index,staticClass:"preview__img",class:{
            'preview__img--two': _vm.propsData.length == 2,
            'preview__img--three-two': _vm.propsData.length == 3,
            'preview__img--four-two': _vm.propsData.length == 4
          },style:([
            image.type === 'video'
              ? ''
              : { backgroundImage: 'url(' + image.url + ')' }
          ]),on:{"click":function($event){return _vm.showImage(index + 1)}}},[(image.type === 'video')?_c('video',{staticClass:"video-layout",attrs:{"preload":"metadata","width":"100%","height":"100%","controlslist":"nodownload","type":"video/*","controls":"","autoplay":"","playsinline":"","muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":image.url,"type":"video/mp4"}}),_c('source',{attrs:{"src":image.url,"type":"video/mov"}})]):_vm._e()])}),0):_vm._e()])]),(_vm.isShowImage)?[_c('div',{staticClass:"image-show"},[_c('button',{staticClass:"btn btn-sm lb-close",on:{"click":_vm.closeShowImage}},[_c('img',{attrs:{"src":require("@/assets/image/background/icon-close.svg"),"alt":""}})]),_c('button',{staticClass:"btn btn-sm lb-next",on:{"click":_vm.nextImage}},[_c('img',{attrs:{"src":require("@/assets/image/background/arrow-right.svg"),"alt":""}})]),_c('button',{staticClass:"btn btn-sm lb-pre",on:{"click":_vm.preImage}},[_c('img',{attrs:{"src":require("@/assets/image/background/arrow-left.svg"),"alt":""}})]),_c('div',{staticClass:"image"},[(_vm.propsData[_vm.index].type === 'video')?_c('video',{staticClass:"image-show-preview-video",attrs:{"preload":"metadata","controlslist":"nodownload","type":"video/*","id":_vm.index,"controls":"","autoplay":"","playsinline":"","muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.propsData[_vm.index].url,"type":"video/mp4"}}),_c('source',{attrs:{"src":_vm.propsData[_vm.index].url,"type":"video/mov"}})]):_c('img',{staticClass:"image-show-preview",attrs:{"src":_vm.propsData[_vm.index].url,"alt":""}})])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }