<template>
  <div>
    <div class="preview-layout">
      <div class="preview-image d-flex justify-content-between">
        <div
          v-if="propsData[0]"
          class="preview__img"
          :class="{
            'preview-image--one': propsData.length == 1,
            'preview-image--two': propsData.length >= 2
          }"
          v-bind:style="[
            propsData[0].type === 'video'
              ? ''
              : { backgroundImage: 'url(' + propsData[0].url + ')' }
          ]"
          @click="showImage(0)"
        >
          <video
            v-if="propsData[0].type === 'video'"
            width="100%"
            height="100%"
            controlslist="nodownload"
            preload="metadata"
            type="video/*"
            class="video-layout"
            controls
            autoplay
            playsinline
            muted
          >
            <source :src="propsData[0].url" type="video/mp4" />
            <source :src="propsData[0].url" type="video/mov" />
          </video>
        </div>
        <div
          v-if="propsData.length > 1"
          class="d-flex flex-column justify-content-between"
          :class="{
            'preview-image--two': propsData.length >= 2
          }"
        >
          <div
            class="preview__img"
            :class="{
              'preview__img--two': propsData.length == 2,
              'preview__img--three-two': propsData.length == 3,
              'preview__img--four-two': propsData.length == 4
            }"
            v-for="(image, index) in getData()"
            :key="index"
            v-bind:style="[
              image.type === 'video'
                ? ''
                : { backgroundImage: 'url(' + image.url + ')' }
            ]"
            @click="showImage(index + 1)"
          >
            <video
              v-if="image.type === 'video'"
              preload="metadata"
              width="100%"
              height="100%"
              controlslist="nodownload"
              type="video/*"
              class="video-layout"
              controls
              autoplay
              playsinline
              muted
            >
              <source :src="image.url" type="video/mp4" />
              <source :src="image.url" type="video/mov" />
            </video>
          </div>
        </div>
      </div>
    </div>
    <template v-if="isShowImage">
      <div class="image-show">
        <button class="btn btn-sm lb-close" @click="closeShowImage">
          <img src="@/assets/image/background/icon-close.svg" alt="" />
        </button>
        <button class="btn btn-sm lb-next" @click="nextImage">
          <img src="@/assets/image/background/arrow-right.svg" alt="" />
        </button>
        <button class="btn btn-sm lb-pre" @click="preImage">
          <img src="@/assets/image/background/arrow-left.svg" alt="" />
        </button>
        <div class="image">
          <video
            v-if="propsData[index].type === 'video'"
            preload="metadata"
            class="image-show-preview-video"
            controlslist="nodownload"
            type="video/*"
            :id="index"
            controls
            autoplay
            playsinline
            muted
          >
            <source :src="propsData[index].url" type="video/mp4" />
            <source :src="propsData[index].url" type="video/mov" />
          </video>
          <img
            v-else
            :src="propsData[index].url"
            class="image-show-preview"
            alt=""
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    propsData: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      isShowImage: false,
      index: 0
    };
  },
  methods: {
    getData() {
      let data = [];
      if (this.propsData.length > 1) {
        for (let i = 1; i < this.propsData.length; i++) {
          data.push(this.propsData[i]);
        }
      }
      return data;
    },
    showImage(index) {
      this.index = index;
      this.isShowImage = true;
    },
    closeShowImage() {
      this.isShowImage = false;
    },
    nextImage() {
      if (this.index == this.propsData.length - 1) {
        this.index = 0;
        return;
      }
      this.index++;
    },
    preImage() {
      if (this.index == 0) {
        this.index = this.propsData.length - 1;
        return;
      }
      this.index--;
    }
  }
};
</script>

<style lang="scss" scoped>
.video-layout {
  pointer-events: none;
}
.image-show {
  transition: opacity 0.2s ease;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: block;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .lb-close {
    border: none;
    box-shadow: none;
    outline: none;
    position: absolute;
    right: 20px;
    top: 20px;
    color: #fff;
    padding: 0.56rem 0.5rem !important;
  }
  .lb-pre {
    position: absolute;
    outline: none;
    border: none;
    box-shadow: none;
    margin-top: 0;
    right: auto;
    left: 20px;
    top: 48%;
  }
  .lb-next {
    position: absolute;
    outline: none;
    border: none;
    box-shadow: none;
    margin-top: 0;
    left: auto;
    right: 20px;
    top: 48%;
  }
  .image {
    position: absolute;
    top: 10px;
    left: 70px;
    right: 70px;
    bottom: 10px;
    text-align: center;
    &::before {
      display: inline-block;
      content: "";
      vertical-align: middle;
      height: 100%;
    }
    &-show-preview {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
      -o-object-fit: contain;
      object-fit: contain;
      pointer-events: none;
    }
    &-show-preview-video {
      width: 500px;
      height: 500px;
      vertical-align: middle;
      -o-object-fit: contain;
      object-fit: contain;
    }
  }
}
.preview-layout {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  .preview-image--two {
    width: calc((100% - 5px) / 2);
    height: 300px;
  }
  .preview__img {
    cursor: pointer;
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    &.preview-image--one {
      width: 100%;
    }
    &.preview-image--two {
      width: calc((100% - 5px) / 2);
      &.preview__image--two {
        width: 100%;
      }
    }
    &.preview__img--three-two {
      width: 100%;
      height: calc((100% - 5px) / 2);
    }
    &.preview__img--four-two {
      width: 100%;
      height: calc((100% - 10px) / 3);
    }
  }
  .preview-image {
    width: 100%;
  }
  @media screen and (max-width: 1080px) {
    .preview__img {
      height: 160px;
    }
  }
}
@media screen and (max-width: 1080px) {
  .preview-layout {
    .preview-image--two {
      height: 160px;
    }
  }
  .image-show {
    .lb-close {
      top: 5px;
      right: 5px;
      padding: 0.56rem 0rem !important;
    }
    .lb-pre {
      left: 5px;
      padding: 0;
    }
    .lb-next {
      right: 5px;
      padding: 0;
    }
    .image {
      position: absolute;
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 20px;
      text-align: center;
      &-show-preview-video {
        background-color: #ffff;
        width: 300px;
        height: 300px;
        vertical-align: middle;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }
  }
}
</style>
