<template>
  <div id="notification" class="scroll-notification">
    <div id="content-scroll" class="content-scroll">
      <div class="content row" id="notification-content">
        <template>
          <template v-if="propsTimeline.length > 0">
            <div
              class="d-flex justify-content-center flex-wrap col-lg-6 timeline"
              v-for="(item, index) in propsTimeline"
              :key="index"
            >
              <TimelineProfileItem
                :propsAvatar="propsAvatar"
                :propsNickname="propsNickname"
                :propsItem="item"
                :propsIdAuth="propsIdAuth"
                @like="like"
              />
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import TimelineProfileItem from "@/views/Common/Timeline/TimelineProfileItem.vue";

export default {
  components: {
    TimelineProfileItem
  },
  props: {
    propsAvatar: {
      type: String,
      default: null
    },
    propsNickname: {
      type: String,
      default: null
    },
    propsTimeline: {
      type: Array,
      default: null
    },
    propsIdAuth: {
      type: Number,
      default: null
    }
  },
  methods: {
    async like(e) {
      await this.$emit("like", e);
    }
  }
};
</script>
<style lang="css" scoped>
@media screen and (min-width: 992px) {
  #notification {
    padding: 0 125px;
  }
  .timeline {
    padding: 0 30px;
  }
}
</style>
