<template>
  <div id="timeline-profile-list">
    <div class="content">
      <transition name="slide-down">
        <div class="detail-notification content__noti-inactive">
          <div
            class="
              d-flex
              justify-content-between
              position-relative
              align-items-center
            "
          >
            <div class="d-flex content__noti--width">
              <div class="content__noti__avatar">
                <div
                  class="position-relative"
                  :class="propsItem.message ? 'avatar__div' : ''"
                >
                  <img class="avatar" :src="propsAvatar" />
                </div>
              </div>
              <div
                class="
                  d-flex
                  flex-column
                  justify-content-center
                  content__noti--description
                "
              >
                <h3 class="f-w6 name text-left mb-0">{{ propsNickname }}</h3>
                <div class="d-flex f-w3">
                  <span class="name__time--span" style="margin-right: 12px">{{
                    formatDateTime(propsItem.createdAt)
                  }}</span>
                  <div
                    class="
                      drop-heart
                      d-flex
                      align-items-center
                      position-relative
                    "
                    :style="{ marginLeft: 20 + 'px' }"
                  >
                    <section>
                      <div
                        class="rt-container"
                        :style="{ right: 22 + 8 * GetNumber() + 'px' }"
                        @click="
                          handleLikeTweet(
                            propsItem.likes.includes(propsIdAuth),
                            propsItem.id
                          )
                        "
                      >
                        <div
                          :id="'heart' + propsItem.id"
                          class="heart"
                          :class="
                            propsItem.likes.includes(propsIdAuth)
                              ? 'active'
                              : ''
                          "
                        ></div>
                      </div>
                    </section>
                    <span class="heart__number">{{
                      propsItem.likes.length
                    }}</span>
                  </div>
                </div>
                <span
                  class="f-w3 description text-left"
                  :id="'description' + propsItem.id"
                  v-html="formatMessage()"
                >
                </span>
              </div>
            </div>
            <!-- <div>
              <b-icon
                icon="chevron-right"
                @click="detailNotification(propsItem.id)"
              />
            </div> -->
          </div>
          <div
            class="preview position-relative mt-3"
            v-if="propsItem.imageUrls.length > 0 && isShowDetailNotification"
          >
            <LayoutImage :propsData="propsItem.imageUrls" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { urlText } from "@/utils/ui";
import LayoutImage from "@/views/Common/Timeline/LayoutImage.vue";

export default {
  name: "TimelineProfileItem",

  components: {
    LayoutImage
  },
  props: {
    propsAvatar: {
      type: String,
      default: null
    },
    propsNickname: {
      type: String,
      default: null
    },
    propsItem: {
      type: Object,
      default: null
    },
    propsIdAuth: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isShowDetailNotification: false,
      disable: false,
      message: null,
      image_timeline: null,
      currentSlide: 0
    };
  },
  methods: {
    formatDateTime(data) {
      if (data) {
        data = data.toDate();
        let dateNow = new Date();
        let time = "";
        if (
          data.getFullYear() === dateNow.getFullYear() &&
          data.getMonth() === dateNow.getMonth() &&
          data.getDate() === dateNow.getDate()
        ) {
          let hour =
            data.getHours() < 10 ? "0" + data.getHours() : data.getHours();
          let minute =
            data.getMinutes() < 10
              ? "0" + data.getMinutes()
              : data.getMinutes();
          time += hour + ":" + minute;
        } else {
          if (data.getFullYear() !== dateNow.getFullYear()) {
            let year = data.getFullYear();
            time += year + "/";
          }
          let day = data.getDate() < 10 ? "0" + data.getDate() : data.getDate();
          let month =
            data.getMonth() + 1 < 10
              ? "0" + (data.getMonth() + 1)
              : data.getMonth() + 1;
          time += month + "/" + day + " ";
        }

        return time;
      }
    },
    formatMessage() {
      this.message = this.propsItem.message;
      return urlText(this.message);
    },
    next() {
      const currentPage = this.$refs["carousel"].currentPage;
      if (currentPage < this.propsItem.imageUrls.length - 1) {
        this.currentSlide++;
        this.$refs["carousel"].goToPage(this.currentSlide);
      }
    },
    pre() {
      const currentPage = this.$refs["carousel"].currentPage;
      if (currentPage > 0) {
        this.currentSlide--;
        this.$refs["carousel"].goToPage(this.currentSlide);
      }
    },
    showImage(url) {
      this.image_timeline = url;
      this.$refs["modalImageTimelineItem"].openModal();
    },
    async handleLikeTweet(is_like, id) {
      let timeline_id = "heart" + id;
      document.getElementById(timeline_id).classList.add("active");
      let params = {
        is_like: is_like,
        id: id
      };
      await this.$emit("like", params);
    },
    GetNumber() {
      return Number(String(this.propsItem.likes.length).length) - 1;
    },
    detailNotification(id) {
      if (this.isShowDetailNotification) {
        this.isShowDetailNotification = false;
        document.getElementById("description" + id).style.display =
          "-webkit-box";
        document.getElementById("description" + id).style.overflow = "hidden";
      } else {
        if (!this.isShowDetailNotification) {
          document.getElementById("description" + id).style.overflow = "unset";
        }
        this.isShowDetailNotification = true;
        this.currentSlide = 0;
        document.getElementById("description" + id).style.display = "block";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.heart__number {
  font-size: 12px;
  color: #818181;
}
.name__time--span {
  font-size: 12px;
  color: #9c9c9c;
}
.preview {
  &__img {
    border-radius: 5px;
  }
  .icon-slider {
    display: none;
    top: 47%;
    transform: translateY(-50%);
    img {
      width: 33px;
      height: 33px;
    }
  }
  .icon-slider-next {
    right: 0;
  }
  .icon-slider-pre {
    left: 0;
  }
}
.map-image {
  width: 100%;
  margin-top: 40px;
  height: 100%;
  max-height: 500px;
}
#timeline-profile-list {
  .pictures {
    // margin : auto;
    margin-top: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      pointer-events: none;
      height: 220px;
      border-radius: 5px;
      margin-left: 2.5px;
      width: calc(100% - 5px);
    }
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

@import "~@/assets/scss/timelineprofile.scss";
@import "~@/assets/scss/_fontFamily.scss";

.content__noti__body {
  margin: 0;
  margin-bottom: 0;
  color: #000;
  font-size: 14px;
  word-break: break-word;
}

@media screen and (min-width: 1200px) {
  .preview {
    .icon-slider {
      display: block;
    }
  }
  .map-image {
    max-height: 500px;
    max-width: 1080px;
  }
  .content__noti__body {
    font-size: 16px;
  }
}
</style>

<style lang="scss" scoped>
.avatar__div {
  margin-top: 5px;
}
@media screen and (min-width: 1200px) {
  .avatar__div {
    margin-top: 0;
  }
}
.rt-container {
  position: absolute;
  z-index: 2;
}
.heart {
  width: 53px;
  height: 53px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: url("~@/assets/image/timeline/heart.png") no-repeat;
  background-position: 0 0;
  cursor: pointer;
  animation: fave-heart 1s steps(28);
  background-size: 1537px;
  &.active {
    background-position: -1484px 0;
    transition: background 1s steps(28);
  }
}
.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 150px;
}
</style>
